<template>
  <div>
    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="companyData === undefined"
    >
      <h4 class="alert-heading">
        Error fetching user data
      </h4>
      <div class="alert-body">
        No user found with this user id. Check
        <b-link
          class="alert-link"
          :to="{ name: 'apps-users-list'}"
        >
          User List
        </b-link>
        for other users.
      </div>
    </b-alert>

    <template v-if="companyData">
      <!-- First Row -->
      <b-row>
        <b-col
          cols="12"
          xl="12"
          lg="12"
          md="12"
        >
          <info-card
            v-if="companyData"
            :company-data="companyData"
            @deactivated="repopulateData"
            @reactivated="repopulateData"
          />
        </b-col>

        <b-col
          cols="12"
        >
          <summary-card
            v-if="companyData"
            :company-data="companyData"
          />
        </b-col>
      </b-row>

      <b-row>
        <b-col
          cols="12"
        >
          <UserManager
            v-if="renderComponent && companyData.id"
            :specified-company-id="companyData.id"
          />
        </b-col>
      </b-row>
    </template>

  </div>
</template>

<script>
import {
  BRow, BCol, BAlert, BLink,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import UserManager from '@/views/user-management-system/UserManager.vue'
import InfoCard from './components/InfoCard.vue'
import SummaryCard from './components/CompanySummaryCard.vue'

export default {
  components: {
    UserManager,
    BRow,
    BCol,
    BAlert,
    BLink,

    // Local Components
    InfoCard,
    SummaryCard,
  },
  data() {
    return {
      companyData: {},
      isLoading: true,
      renderComponent: true,
    }
  },
  created() {
    this.get_company_data()
    // this.populateMockData()
  },
  methods: {
    populateMockData() {
      const { id } = this.$route.params
      this.companyData = {
        id: '326b9a27-4104-4966-8b56-500943805aaa',
        company_uen_no: '201721612D',
        uen_no: '201721612D',
        company_name: 'HEADHUNTERS HQ PTE. LTD.',
        company_status: true,
        active: true,
        company_summary: 'HeadHunters HQ is a HR Tech marketplace for Talent Acquisition/Recruitment Solutions that seeks to connect Recruitment Agencies, Direct Employers and Job Seekers together. The marketplace houses integrated modular solutions and with each of these solutions designed and engineered to streamline existing processes and empower organisations with the cutting edge to hire faster, better and the right talent.',
      }
    },
    get_company_data() {
      const { token } = this.$store.state.auth.ActiveUser
      const { id } = this.$route.params

      this.companyData = {}
      this.isLoading = true
      this.$http.defaults.headers.common.Authorization = `Token ${token}`

      this.$http
        .get(`/api/companies/${id}`)
        .then(response => {
          this.companyData = response.data
          this.isLoading = false

          // this.get_company_users_data()
        })
        .catch(error => {
          this.$handleErrorResponse(error, 'Operation Failed', 'Could not retrieve the company details. Please try again later!')

          this.isLoading = false
        })
    },
    get_company_users_data() {
      const { token } = this.$store.state.auth.ActiveUser

      this.isLoading = true
      this.renderComponent = false
      this.$http.defaults.headers.common.Authorization = `Token ${token}`

      this.$http
        // .get(`/api/companies/users/${this.$route.params.id}`)
        .get(`/api/all/company-users/${this.$route.params.id}`)
        .then(response => {
          this.renderComponent = true
          this.companyData.users = response.data.users

          this.isLoading = false
        })
        .catch(error => {
          this.$handleErrorResponse(error, 'Operation Failed', 'Could not retrieve the company user list. Please try again later!')

          this.isLoading = false
        })
    },

    repopulateData() {
      this.companyData = {}

      this.get_company_data()
      // this.get_company_users_data()
    },
  },
}
</script>

<style>

</style>
